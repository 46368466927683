<template>
  <div class="store-shelf">
    <shelf-title :title="$t('shelf.title')"></shelf-title>
    <scroll-view class="store-shelf-scroll-wrapper"
                 :top="0"
                 @onScroll="onScroll"
                 :bottom="scrollBottom"
                 :initPosition="initPosition"
                 ref="scroll">
      <shelf-search ></shelf-search>
      <shelf-list :data="shelfList"></shelf-list>
      <!-- <book-shelf-empty
                  class="book-shelf-empty"
                  v-if="isDataEmpty"></book-shelf-empty> -->
    </scroll-view>
    <shelf-footer class="book-shelf-footer"></shelf-footer>
  </div>
</template>

<script type="text/ecmascript-6">
  import ShelfTitle from '@/components/shelf/ShelfTitle'
  import ScrollView from '@/components/common/Scroll'
  import ShelfSearch from '@/components/shelf/ShelfSearch'
  import ShelfList from '@/components/shelf/ShelfList'
  import ShelfFooter from '@/components/shelf/ShelfFooter'
  // import BookShelfEmpty from '@/components/shelf/bookShelfEmpty'
import { storeShelfMixin } from '../../utils/mixin'
import Epub from 'epubjs'
global.ePub = Epub

  // const BOOK_SHELF_KEY = 'bookShelf'
  export default {
    mixins: [storeShelfMixin],
    components: {
      ShelfTitle,
      ShelfSearch,
      ScrollView,
      ShelfFooter,
      ShelfList
      // BookShelfEmpty,
    },
    watch: {
      isEditMode(isEditMode) {
        this.scrollBottom = isEditMode ? 48 : 0
        this.$nextTick(() => {
          this.$refs.scroll.refresh()
        })
      }
    },
    data() {
      return {
        scrollBottom: 0,
        toastText: '',
        initPosition: {
          x: 0,
          y: 0
        }
      }
    },
    methods: {
      onScroll(offsetY) {
        this.setOffsetY(offsetY)
      }
    },
    mounted() {
      if (!this.shelfList || this.shelfList.length === 0) {
        this.getShelfList()
      }
      this.setShelfCategory([])
      this.setCurrentType(1)
    }
  }
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
  @import "../../assets/styles/global";
  .store-shelf {
    position: relative;
    z-index: 100;
    width: 100%;
    height: 100%;
    background: white;
    .store-shelf-scroll-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 101;
    }
  }
</style>
